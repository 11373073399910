import type { GetServerSidePropsContext, NextPage } from 'next'
import { validate } from 'uuid'

import Screen, { StoreIdScreenProps } from 'screens/explore/stores/storeId'

import { api } from 'config/apiClient'

import { StoreIdMapping } from 'utils/store'
import { normalizePaymentMethod } from 'utils/paymentMethod'
import { isEmpty } from 'lodash'
import { SWRConfig, SWRConfiguration, unstable_serialize } from 'swr'

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const cookie = context.req.headers.cookie

  if (StoreIdMapping[context.params?.storeId as string]) {
    return {
      redirect: {
        destination: `/explore/stores/${
          StoreIdMapping[context.params?.storeId as string]
        }`,
      },
    }
  }

  const isUuid = validate(context.params?.storeId as string)

  if (!isUuid) {
    return {
      redirect: {
        destination: '/explore/stores',
      },
    }
  }

  const storeId = context.params?.storeId as string

  const [storeDetails, userPaymentMethods] = await Promise.all([
    api('getStoreDetailsById', { storeId }, cookie),
    api(
      'getUserPaymentMethods',
      { storeId, excludeJoebucks: true, type: 'joebucks' },
      cookie
    ),
  ])

  const data = storeDetails.data

  const {
    success,
    data: { paymentMethods },
  } = userPaymentMethods

  let balance: number | null = null

  if (success) {
    balance = isEmpty(paymentMethods)
      ? 0
      : (normalizePaymentMethod(paymentMethods[0]).amount as number)
  }

  return {
    props: {
      fallback: {
        [unstable_serialize(['getStoreDetailsById', { storeId }])]: { data },
      },
      balance,
    },
  }
}

const StoreId: NextPage<
  StoreIdScreenProps & { fallback: SWRConfiguration['fallback'] }
> = ({ fallback, ...props }) => (
  <SWRConfig
    value={{
      fallback,
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }}
  >
    <Screen {...props} />
  </SWRConfig>
)

export default StoreId
